<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit User Information </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.name"
                outlined
                dense
                :label="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.registrationName"
                outlined
                dense
                :label="$t('registrationName')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.taxId"
                outlined
                dense
                :label="$t('taxId')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.contactMail"
                outlined
                dense
                :label="$t('contactEmail')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    companyData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)
    const bioEditForm = ref(null)
    const companyDataLocal = ref({})
    companyDataLocal.value = JSON.parse(JSON.stringify(props.companyData))
    const { updateCompanySettings } = useActions('company', ['updateCompanySettings'])

    // on form submit
    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateCompanySettings({
        id: companyDataLocal.value.id,
        name: companyDataLocal.value.name,
        registrationName: companyDataLocal.value.registrationName,
        taxId: companyDataLocal.value.taxId,
        contactMail: companyDataLocal.value.contactMail,
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
        emit('refetch-company-data')
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        companyDataLocal.value = JSON.parse(JSON.stringify(props.companyData))
      },
    )

    const areDataChanged = computed(() => {
      return (
        companyDataLocal.value.name !== props.companyData.name ||
        companyDataLocal.value.registrationName !== props.companyData.registrationName ||
        companyDataLocal.value.taxId !== props.companyData.taxId ||
        companyDataLocal.value.contactMail !== props.companyData.contactMail
      )
    })

    return {
      valid,
      loading,
      bioEditForm,
      companyDataLocal,
      areDataChanged,
      validators: {
        required,
      },

      handleFormSubmit,
    }
  },
}
</script>
