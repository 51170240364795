<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="companyData.avatar ? '' : 'primary'"
            :class="companyData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img v-if="companyData.avatar" :src="require(`@/assets/images/avatars/${companyData.avatar}`)"></v-img>
            <span v-else class="font-weight-semibold text-5xl">{{ avatarText(`${companyData.name}`) }}</span>
          </v-avatar>

          <span class="mb-2">{{ companyData.name }}</span>
        </v-card-title>
        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('name') }}:</span>
              <span class="text--secondary">{{ companyData.name }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('registrationName') }}:</span>
              <span class="text--secondary">{{ companyData.registrationName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('taxId') }}:</span>
              <span class="text--secondary">{{ companyData.taxId }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('contactEmail') }}:</span>
              <span class="text--secondary">{{ companyData.contactMail }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="isBioDialogOpen = !isBioDialogOpen"> {{ $t('edit') }} </v-btn>
        </v-card-actions>
      </v-card>

      <company-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :company-data="companyData"
        @refetch-company-data="emit('refetch-company-data')"
      ></company-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import CompanyBioEdit from './CompanyBioEdit.vue'

export default {
  components: {
    CompanyBioEdit,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)

    const isBioDialogOpen = ref(false)

    return {
      emit,

      avatarText,
      kFormatter,

      isDialogVisible,
      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
