<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <company-bio-panel
          class="user-bio-col"
          :company-data="companyData"
          @refetch-company-data="fetchCompanyData"
        ></company-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="companyTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" v-model="companyTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <company-tab-address
              :company-data.sync="companyData"
              :frozen-company-data.sync="frozenCompanyData"
              @refetch-company-data="fetchCompanyData"
            ></company-tab-address>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { ref, onMounted } from '@vue/composition-api'
import { mdiAccountOutline, mdiLockOutline } from '@mdi/js'
import { useActions } from 'vuex-composition-helpers'
// eslint-disable-next-line object-curly-newline
import CompanyBioPanel from './company-bio-panel/CompanyBioPanel.vue'
import CompanyTabAddress from './company-tab-address/CompanyTabAddress.vue'

export default {
  components: {
    CompanyBioPanel,
    CompanyTabAddress,
  },
  setup() {
    const companyData = ref({})
    const frozenCompanyData = ref({})
    const companyTab = ref(null)

    const { getCompanySettings } = useActions('company', ['getCompanySettings'])

    const fetchCompanyData = async () => {
      const { data, success } = await getCompanySettings()
      if (success) {
        companyData.value = data
        frozenCompanyData.value = Object.freeze(_.cloneDeep(companyData.value))
      }
    }

    onMounted(async () => {
      await fetchCompanyData()
    })

    const tabs = [{ icon: mdiAccountOutline, title: 'address' }]

    return {
      tabs,
      companyTab,
      companyData,
      frozenCompanyData,

      fetchCompanyData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
