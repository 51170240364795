<template>
  <div class="user-tab-overview">
    <v-card class="mb-7">
      <v-card-title v-t="'address'" />
      <v-card-text>
        <v-form ref="addressForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="companyData.street"
                outlined
                dense
                :label="$t('street')"
                :placeholder="$t('street')"
                maxlength="100"
                :rules="[validators.required]"
              ></v-text-field
            ></v-col>
            <v-col cols="6" md="3"
              ><v-text-field
                v-model="companyData.houseNumber"
                outlined
                dense
                :label="$t('houseNumber')"
                :placeholder="$t('houseNumber')"
                maxlength="20"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                v-model="companyData.flatNumber"
                outlined
                dense
                :label="$t('flatNumber')"
                :placeholder="$t('flatNumber')"
                maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="companyData.city"
                outlined
                dense
                :label="$t('city')"
                :placeholder="$t('city')"
                maxlength="50"
                :rules="[validators.required]"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="companyData.zipCode"
                outlined
                dense
                :label="$t('zipCode')"
                :placeholder="$t('zipCode')"
                maxlength="10"
                :rules="[validators.required]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="companyData.state"
                outlined
                dense
                :label="$t('state')"
                :placeholder="$t('state')"
                maxlength="50"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="companyData.country"
                outlined
                dense
                :label="$t('country')"
                :placeholder="$t('country')"
                maxlength="50"
                :rules="[validators.required]"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="loading"
                :disabled="!valid || !areDataChanged"
              >
                {{ $t('save') }}
              </v-btn>
              <v-btn v-t="'discard'" outlined color="secondary" :disabled="!areDataChanged" @click="restoreData" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { mdiDotsVertical } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useActions } from 'vuex-composition-helpers'

export default {
  props: {
    companyData: {
      type: Object,
      required: true,
    },
    frozenCompanyData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const addressForm = ref(null)
    const valid = ref(true)
    const loading = ref(false)

    const { updateCompanySettings } = useActions('company', ['updateCompanySettings'])

    const handleFormSubmit = async () => {
      const isFormValid = addressForm.value.validate()
      if (!isFormValid) return

      loading.value = true

      const { success } = await updateCompanySettings({
        id: props.companyData.id,
        street: props.companyData.street,
        houseNumber: props.companyData.houseNumber,
        flatNumber: props.companyData.flatNumber,
        city: props.companyData.city,
        zipCode: props.companyData.zipCode,
        state: props.companyData.state,
        country: props.companyData.country,
      })
      if (success) emit('refetch-company-data')
      loading.value = false
    }

    const restoreData = () => {
      emit('update:companyData', _.cloneDeep(props.frozenCompanyData))
    }

    const areDataChanged = computed(() => {
      return (
        props.companyData.street !== props.frozenCompanyData.street ||
        props.companyData.houseNumber !== props.frozenCompanyData.houseNumber ||
        props.companyData.flatNumber !== props.frozenCompanyData.flatNumber ||
        props.companyData.zipCode !== props.frozenCompanyData.zipCode ||
        props.companyData.state !== props.frozenCompanyData.state ||
        props.companyData.country !== props.frozenCompanyData.country ||
        props.companyData.city !== props.frozenCompanyData.city
      )
    })

    return {
      handleFormSubmit,
      restoreData,

      areDataChanged,

      validators: {
        required,
      },
      addressForm,
      valid,
      loading,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
